<template>
    <el-main>
        <el-form class="el-form-search" label-width="100px">
            <el-form-item label="会员姓名：">
                <el-input size="small" v-model="ruleForm.nick_name" placeholder="请输入会员姓名："></el-input>
            </el-form-item>
            <el-form-item label="联系电话：">
                <el-input size="small" v-model="ruleForm.mobile" placeholder="请输入联系电话"></el-input>
            </el-form-item>
            <el-form-item label="申请日期：">
                <el-date-picker size="small" v-model="ruleForm.time" type="daterange" range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="合作标题：">
                <el-input size="small" v-model="ruleForm.title" placeholder="请输入合作标题："></el-input>
            </el-form-item>
            <el-form-item label="申请状态：">
                <el-select size="small" v-model="ruleForm.status" placeholder="请选择">
                    <el-option v-for="item in statusList" :key="item.id" :label="item.name"
                        :value="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="合作项目：">
                <el-select size="small" v-more="loadMoreTypeFrom" v-model="ruleForm.c_id" filterable remote
                    :remote-method="getCList" placeholder="请选择" class="classify_select">
                    <el-option v-for="item in CInfo.list" :key="item.id" :label="item.title" :value="item.id">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label=" " label-width="25px">
                <el-button size="small" type="primary" @click="getDataList(1)">搜索</el-button>
                <el-button size="small" type="text" @click="clearSearch">清除搜索条件</el-button>
            </el-form-item>
        </el-form>
        <el-table :data="dataList" style="width: 100%" :header-cell-style="{ 'background-color': '#F8F9FA' }">
            <template slot="empty">
                <No />
            </template>
            <el-table-column label="申请日期" align="center">
                <template v-slot="{ row }">{{ getDateformat(row.create_time) }}</template>
            </el-table-column>
            <el-table-column prop="nick_name" label="会员姓名" align="center"></el-table-column>
            <el-table-column prop="mobile" label="联系电话" align="center"></el-table-column>
            <el-table-column prop="title" label="合作标题" align="center"></el-table-column>
            <el-table-column prop="reason" label="申请理由" align="center"></el-table-column>
            <el-table-column prop="cate_name" label="申请状态" align="center">
                <template v-slot="{ row }">
                    <div :style="{ color: row.status === -1 ? '#f56c6c' : row.status === 0 ? '#e6a23c' : '#409eff' }">
                        {{ row.status === -1 ? '已驳回' : row.status === 0 ? '未审核' : '已推荐' }}</div>
                </template>
            </el-table-column>
            <el-table-column prop="cate_name" label="操作" align="center">
                <template v-slot="{ row }">
                    <el-button type="text" @click="lookProject(row)">查看项目</el-button>
                    <el-button type="text" v-if="!row.status" @click="recommendFn(row)">推荐</el-button>
                    <el-button type="text" v-if="!row.status" @click="rejectFn(row)">驳回</el-button>
                    <el-button type="text" v-if="row.status === -1" @click="lookRejectFn(row)">驳回</el-button>
                </template>
            </el-table-column>
        </el-table>
        <Paging :total="dataTotal" :page="ruleForm.page" :pageNum="ruleForm.rows" @updatePageNum="updateData"></Paging>
        <!-- 查看项目弹框 -->
        <el-dialog title="查看项目" :visible.sync="showCooperation" width="500px">
            <el-form ref="form" label-width="120px">
                <el-form-item label="项目标题：">{{ nowEditData.title }}</el-form-item>
                <el-form-item label="项目类型：">{{ nowEditData.project_name }}</el-form-item>
                <el-form-item label="需求类型：">{{ nowEditData.need_type }}</el-form-item>
                <el-form-item label="预计孵化周期：">{{ nowEditData.time }}</el-form-item>
                <el-form-item label="项目启动资金：">{{ nowEditData.start_price }}</el-form-item>
                <el-form-item label="市场预期收益：">{{ nowEditData.price }}</el-form-item>
                <el-form-item label="项目实施计划：">{{ nowEditData.plan }}</el-form-item>
                <el-form-item label="所属区域：">{{ nowEditData.province_name + nowEditData.city_name }}</el-form-item>
                <el-form-item label="到期时间">{{ getDateformat(nowEditData.end_time) }}</el-form-item>
            </el-form>
        </el-dialog>
        <!-- 驳回弹框 -->
        <el-dialog title="驳回" :visible.sync="rejectDialogFlag" width="400px" @closed="dealReject(0)">
            <div>
                <p style="margin-bottom:20px">请添加驳回原因：</p>
                <el-input v-model="rejectResultTxt" type="textarea" :rows="5"></el-input>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dealReject(0)">取 消</el-button>
                <el-button type="primary" @click="dealReject(1)">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog title="驳回原因" :visible.sync="rejectResultFlag" width="400px">
            <div>{{ nowEditData.refuse_reason }}</div>
        </el-dialog>
    </el-main>
</template>

<script>
import Paging from '@/components/paging.vue';
import { getDateformat } from '@/util/getDate.js'
export default {
    components: {
        Paging
    },
    data () {
        return {
            getDateformat,
            ruleForm: {
                page: 1,
                rows: 10,
                nick_name: '',
                mobile: '',
                time: [],
                title: '',
                status: -2,
                c_id: null
            },
            statusList: [
                { id: -2, name: "全部" },
                { id: -1, name: "驳回" },
                { id: 1, name: "推荐" },
                { id: 0, name: "未审核" },
            ],
            dataList: [],
            dataTotal: 0,
            showCooperation: false,
            nowEditData: {},
            rejectDialogFlag: false,
            rejectResultFlag: false,
            rejectResultTxt: '',
            CInfo: {
                name: '',
                page: 1,
                rows: 10,
                total: 0,
                list: []
            }
        }
    },
    created () {
        if (this.$route.query.cid) {
            this.ruleForm.c_id = Number(this.$route.query.cid)
        }
        this.getDataList()
        this.getCList()
    },
    // 自定义指令
    directives: {
        more: {
            bind (el, binding) {
                const SELECTWRAP_DOM = el.querySelector('.classify_select .el-scrollbar .el-select-dropdown__wrap');
                SELECTWRAP_DOM.addEventListener('scroll', function () {
                    const condition = this.scrollHeight - this.scrollTop <= this.clientHeight;
                    if (condition) {
                        binding.value();
                    }
                });
            },
        },
    },
    methods: {
        loadMoreTypeFrom () {
            if (Math.ceil(this.CInfo.total / this.CInfo.rows) > this.CInfo.page) {
                this.CInfo.page++;
                this.getCList(this.CInfo.name, 1);
            }
        },
        getCList (name, is_more) {
            if (!is_more) {
                this.CInfo.page = 1;
            }
            this.CInfo.name = name;
            this.$axios
                .post(this.$api.gateway.cooperation.getList, {
                    page: this.CInfo.page,
                    rows: this.CInfo.rows,
                    title: name,
                })
                .then(res => {
                    if (res.code === 0) {
                        if (is_more) {
                            this.CInfo.list = this.CInfo.list.concat(res.result.list);
                        } else {
                            this.CInfo.list = res.result.list || [];
                        }
                        this.CInfo.total = res.result.total;
                    }
                });
        },
        // 处理驳回
        dealReject (style) {
            if (!style) {
                // 取消驳回
                this.rejectDialogFlag = false
                this.rejectResultTxt = ''
            } else {
                // 确定驳回
                if (this.rejectResultTxt === '') {
                    this.$message.warning('请填写驳回理由!!!')
                    return
                }
                this.$axios.post(this.$api.gateway.cooperation.updateStatus, {
                    id: this.nowEditData.id,
                    status: -1,
                    reason: this.rejectResultTxt
                }).then(res => {
                    if (res.code === 0) {
                        this.$message.success('驳回成功')
                        this.dealReject(0)
                        this.getDataList()
                    } else {
                        this.$message.error(res.msg)
                    }
                })
            }
        },
        // 驳回项目
        rejectFn (data) {
            this.nowEditData = data
            this.rejectDialogFlag = true
        },
        lookRejectFn (data) {
            this.nowEditData = data
            this.rejectResultFlag = true
        },
        // 推荐项目
        recommendFn (data) {
            this.$confirm('此操作将推荐该合作, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                return this.$axios.post(this.$api.gateway.cooperation.updateStatus, {
                    id: data.id,
                    status: 1
                })
            }).then(res => {
                if (res.code === 0) {
                    this.$message.success('已成功推荐该合作')
                    data.status = 1
                } else {
                    this.$message.error(res.msg)
                    data.status = 0
                }
            }).catch(() => {
                data.status = 0
            })
        },
        // 查看合作项目
        lookProject (data) {
            this.$axios.post(this.$api.gateway.cooperation.info, {
                id: data.cooperation_id
            }).then(res => {
                if (res.code == 0) {
                    this.nowEditData = res.result
                    this.showCooperation = true
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        updateData (val, status) {
            if (status == 0) {
                this.ruleForm.rows = val;
            } else {
                this.ruleForm.page = val;
            }
            this.getDataList()
        },
        getDataList (style) {
            if (style) this.ruleForm.page = 1
            let obj = {
                page: this.ruleForm.page,
                rows: this.ruleForm.rows,
            }
            if (this.ruleForm.title) obj.title = this.ruleForm.title
            if (this.ruleForm.nick_name) obj.nick_name = this.ruleForm.nick_name
            if (this.ruleForm.mobile) obj.mobile = this.ruleForm.mobile
            if (this.ruleForm.c_id) obj.c_id = this.ruleForm.c_id
            if (this.ruleForm.status !== -2) obj.status = this.ruleForm.status
            if (this.ruleForm.time?.length) {
                obj.start_time = Math.ceil(this.ruleForm.time[0].getTime() / 1000)
                obj.end_time = Math.ceil(this.ruleForm.time[1].getTime() / 1000)
                obj.end_time = obj.start_time == obj.end_time ? obj.end_time + 60 * 60 * 24 - 1 : obj.end_time
            }
            this.$axios.post(this.$api.gateway.cooperation.getJoinList, obj).then(res => {
                if (res.code == 0) {
                    this.dataList = res.result.list
                    this.dataTotal = res.result.total
                } else {
                    this.$message.error(res.msg)
                }
            })
        },
        clearSearch () {
            this.ruleForm = {
                page: 1,
                rows: 10,
                nick_name: '',
                mobile: '',
                time: [],
                title: '',
                status: -2,
            }
            this.getDataList()
        },
    }
}
</script>

<style lang="less" scoped>
.el-main {
    background-color: #fff;
}
</style>